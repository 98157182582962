// TODO: there are a lot of unused rules here. We should remove them.
@use "variables" as *;
@use "fonts" as *;
@use "mixins/reset-button" as *;

/* stylelint-disable selector-no-qualifying-type */
* {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  min-height: 100vh;
}

body {
  color: $text-primary;
  font-family: $primary-font-family;
  font-size: $default-font-size;
  line-height: $default-line-height;
  margin: 0;
  min-width: 320px;
}

// Needed to properly hide elements in Angular 4: https://stackoverflow.com/a/35578093/2379097
[hidden] {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}

// Removes red borders around invalid form inputs in Firefox
// Fixes https://github.com/workato/issues/issues/7658
// More details: https://developer.mozilla.org/en-US/docs/Web/CSS/:invalid
:invalid {
  box-shadow: none;
}

:-moz-submit-invalid {
  box-shadow: none;
}

:-moz-ui-invalid {
  box-shadow: none;
}

@media (min-width: $screen-min-width-desktop) and (max-width: $screen-max-width-desktop) {
  .container {
    width: 880px;
  }
}

.hidden {
  display: none;
}

.api-layout-body {
  padding: 15px;
}

a {
  color: $link-primary-color;
  text-decoration: underline;

  &:hover {
    color: $link-primary-hover-color;
    text-decoration: underline;
  }

  &.disabled {
    color: $text-disabled;
    cursor: default;

    &:hover {
      text-decoration: none;
    }
  }
}

p {
  margin: 0 0 1em;
}

.title {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 0.2em;
}

.deprecated-property {
  color: $text-secondary;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

h2 {
  &.title {
    padding-top: 5px;
  }
}

[draggable="false"] {
  cursor: default;
}

.text-positive {
  /* stylelint-disable-next-line declaration-no-important */
  color: $teal-fg !important;
}

pre,
code {
  background-color: $surface-alt;
  border-radius: 2px;
  color: $text-primary;
  font-family: $source-code-font-family;
  font-size: 12px;
  padding: 2px 4px;
}

pre {
  border: none;
  display: block;
  line-height: $default-line-height;
  margin: 5px 0 10px;
  overflow: auto;
  padding: 12px 15px;
  word-break: break-all;
  word-wrap: break-word;

  &:last-child {
    margin-bottom: 0;
  }
}

.content {
  margin: 0 0 25px;
}

// hotkeys cheat sheet styling
.cfp-hotkeys-close {
  border: none;
  font-size: 1.5em;
}

.cfp-hotkeys {
  font-size: 1em;
}

.cfp-hotkeys-title {
  margin-top: -75px;
}

#api-page-loading {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;

  .api-loading-spin {
    fill: $teal-default;
    height: 50px;
    left: 50%;
    margin: -25px 0 0 -25px;
    position: absolute;
    top: 50%;
    width: 50px;
  }

  .api-page-loaded & {
    display: none;
  }
}

#api-page {
  opacity: 0;
  transition: opacity 1s ease;

  .api-page-loaded & {
    opacity: 1;
  }
}

.no-select {
  user-select: none;
}

.no-transition * {
  /* stylelint-disable-next-line declaration-no-important */
  transition: none !important;
}

.w-separator {
  color: $neutral-border;
  margin: 0 10px;
}

.trap-scroll {
  overscroll-behavior: contain;
}

// Styles inherited from bootstrap css for backward compatibility
/* stylelint-disable */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

b,
strong {
  font-weight: 600;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

hr {
  border: 0;
  border-top: 1px solid $border-subtle;
  height: 0;
  box-sizing: content-box;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input {
  line-height: normal;

  &::-ms-clear {
    display: none;
  }
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

td,
th {
  padding: 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
}

p {
  margin: 0 0 10px;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
}

ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}

input[type="file"] {
  display: block;
}
/* stylelint-enable */
