@use "variables" as *;
@use "mixins" as *;

.recipe-step {
  $block: &;

  $header-border-width: 1px;

  position: relative;
  width: $recipe-step-width;

  @media print {
    break-inside: avoid;
  }

  @at-root w-recipe-step {
    display: block;
  }

  &__header {
    align-items: center;
    background: $recipe-step-background;
    border: $header-border-width solid $recipe-step-border-color;
    border-radius: $recipe-step-border-radius;
    cursor: default;
    display: flex;
    padding: $recipe-step-header-padding;
    position: relative;
    transition:
      opacity $recipe-step-transition-timing,
      $primary-hover-transition;

    &:hover {
      border-color: $neutral-border-emphasis;
      box-shadow: $step-hover-shadow;

      @include device-min(compact) {
        .recipe-step__actions {
          opacity: 1;
        }
      }
    }

    .recipe-step_disabled & {
      border: none;

      &:hover {
        box-shadow: none;
      }
    }

    .recipe-step_skipped & {
      @include stripe-pattern-bg($recipe-step-stripe-width, $backdrop-default, $surface-default);

      border-color: $border-subtle;

      &:hover {
        border-color: $neutral-border-emphasis;
        box-shadow: $step-hover-shadow;
      }
    }

    .recipe-step_selected.recipe-step_skipped &,
    .recipe-step_skipped.recipe-step_active & {
      @include stripe-pattern-bg($recipe-step-stripe-width, $accent-tint, $surface-default);

      &:hover {
        border-color: $accent-border;
        box-shadow: $step-selected-shadow;
      }
    }

    .recipe-step_editing & {
      background-color: $neutral-tint;
      border-color: $accent-border-emphasis;
      cursor: default;
    }

    .recipe-step_active & {
      background-color: $accent-tint;
      border-color: $accent-border;
      box-shadow: $step-selected-shadow;
    }

    .recipe-step-draggable:not(.recipe-step_editing) &:hover::after {
      @include drag-handle;

      content: "";
      height: 24px;

      @include device(tablet) {
        display: none;
      }
    }

    .recipe-step_with-details & {
      cursor: pointer;
    }

    .recipe-step_with-error & {
      background: $error-tint;
      border-color: $error-border;

      &:hover {
        border-color: $error-border-emphasis;
        box-shadow: $step-error-hover-shadow;
      }
    }

    .recipe-step_with-error.recipe-step_active & {
      background: $error-tint;
      border-color: $error-border-emphasis;
      box-shadow: $step-error-selected-shadow;
    }

    .recipe-step_with-error.recipe-step-draggable:not(.recipe-step_editing) &:hover::after {
      @include drag-handle-color($error-fg);
    }

    .recipe-step_selected & {
      &,
      &:hover {
        background-color: $accent-tint;
        border-color: $accent-border;
        box-shadow: none;
        cursor: default;
      }

      .recipe-step__actions {
        opacity: 0;
      }
    }

    .w-dnd__active & {
      pointer-events: none;
    }
  }

  &__icon {
    align-self: stretch;
    flex: 0 0 auto;
  }

  &__problem-icon {
    @include reset-button;

    cursor: pointer;
    position: absolute;
    right: 46px;

    &_error {
      color: $error-fg;
    }

    &_warning {
      color: $warning-fg;
    }

    &_suggestion {
      color: $ai-default;
    }
  }

  &__title-container {
    flex: 1;
    margin: 0 8px;
  }

  &__title {
    vertical-align: middle;
  }

  &_sketch {
    background: $neutral-tint;
  }

  &__sketch-title {
    display: inline-block;
    font-family: $kalam-font-family;
    line-height: 1.7;
    vertical-align: middle;
    white-space: normal;
  }

  &__title-skeleton {
    --text-skeleton-line-height: 20px;
    --text-skeleton-line-animation-duration: 1.2s;

    display: block;
    max-width: 278px;
  }

  &__suggested-comment {
    --recipe-step-comment-color: #{$ai-fg};
    --recipe-step-comment-background: #{$ai-alt-tint};
  }

  &__actions {
    height: 100%;
    left: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.3s ease;

    @include device-min(compact) {
      &.visible {
        opacity: 1;
      }
    }

    @include device(compact) {
      opacity: 1;
    }

    @include device(phone) {
      display: none;
    }
  }

  &_sketch-review {
    color: $text-secondary;

    #{$block}__header {
      background: $surface-opacity-15;
      border: $header-border-width dashed $border-default;
      border-radius: 0;

      &:hover {
        border: $header-border-width dashed $border-default;
        box-shadow: none;
      }
    }
  }

  &_sketch-configuring {
    #{$block}__header {
      background: $surface-opacity-15;
      border: $header-border-width solid $recipe-step-border-color;

      &:hover {
        border: $header-border-width solid $recipe-step-border-color;
        box-shadow: none;
      }
    }
  }

  &_editing {
    .recipe-step-icon,
    .recipe-step-title {
      opacity: 0.4;
    }
  }

  &_with-error {
    .recipe-step-custom-title__title,
    .recipe-step-title .provider {
      color: $error-fg;
    }
  }

  &__number {
    color: $text-secondary;
    cursor: default;
    padding: 5px 10px 5px 15px;
    position: absolute;
    top: 10px;
    transform: translateX(-100%);
    user-select: none;

    @include device(phone) {
      font-size: 12px;
      padding: 5px 6px 5px 9px;
      top: 13px;
    }
  }

  &__number-text {
    min-width: 26px;
    text-align: right;
  }

  &__number-button {
    @include reset-button;

    min-width: 26px;
    text-align: right;

    .recipe-step_collapsible & {
      cursor: pointer;
    }

    &__text {
      position: relative;

      .recipe-step_skipped & {
        color: $neutral-muted;

        &::after {
          border-top: 1px solid $neutral-muted;
          content: "";
          height: 1px;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translateX(-50%) rotate(-45deg);
          width: 18px;
        }
      }
    }
  }

  &__labels {
    position: absolute;
    right: 8px;
    top: -12px;
    z-index: 1;
  }

  &__collapse-toggle {
    display: none;
    margin-right: 4px;
    position: relative;
    top: -2px;
    transition: transform 0.2s ease;
  }

  &_collapsible > &__number &__collapse-toggle {
    display: inline-block;
  }

  &_collapsed > &__number &__collapse-toggle {
    transform: rotate(-90deg);
  }
}
