// Page background
$backdrop-default: var(--backdrop-default);
$backdrop-landing: var(--backdrop-landing);
$backdrop-dev: var(--backdrop-dev);
$backdrop-test: var(--backdrop-test);
$backdrop-prod: var(--backdrop-prod);
$backdrop-non-env: var(--backdrop-non-env);

// Text
$text-primary: var(--text-primary);
$text-secondary: var(--text-secondary);
$text-inverse: var(--text-inverse);
$text-inverse-opacity-15: var(--text-inverse-opacity-15);
$text-emphasis: var(--text-emphasis);
$text-disabled: var(--text-disabled);
$text-placeholder: var(--text-placeholder);

// Surface
$surface-default: var(--surface-default);
$surface-elevated: var(--surface-elevated);
$surface-field: var(--surface-field);
$surface-subtle: var(--surface-subtle);
$surface-subtle-opacity-70: var(--surface-subtle-opacity-70);
$surface-alt: var(--surface-alt);
$surface-inverse: var(--surface-inverse);
$surface-inverse-opacity-3: var(--surface-inverse-opacity-3);
$surface-inverse-opacity-5: var(--surface-inverse-opacity-5);
$surface-inverse-opacity-8: var(--surface-inverse-opacity-8);
$surface-inverse-opacity-15: var(--surface-inverse-opacity-15);
$surface-inverse-opacity-70: var(--surface-inverse-opacity-70);
$surface-opacity-15: var(--surface-opacity-15);
$surface-opacity-50: var(--surface-opacity-50);
$surface-opacity-70: var(--surface-opacity-70);
$surface-opacity-80: var(--surface-opacity-80);
$surface-opacity-95: var(--surface-opacity-95);
$surface-alt-opacity-75: var(--surface-alt-opacity-75);

// Link
$link-default: var(--link-default);
$link-emphasis: var(--link-emphasis);

// Icon
$icon-default: var(--icon-default);
$icon-emphasis: var(--icon-emphasis);
$icon-inverse: var(--icon-inverse);
$icon-placeholder: var(--icon-placeholder);
$icon-disabled: var(--icon-disabled);

// Sidebar
$sidebar-main: var(--sidebar-main);
$sidebar-active: var(--sidebar-active);
$sidebar-fg-active: var(--sidebar-fg-active);
$sidebar-submenu: var(--sidebar-submenu);
$sidebar-fg-secondary: var(--sidebar-fg-secondary);
$sidebar-fg-primary: var(--sidebar-fg-primary);
$sidebar-border: var(--sidebar-border);
$sidebar-border-opacity-30: var(--sidebar-border-opacity-30);
$sidebar-fg-secondary-opacity-30: var(--sidebar-fg-secondary-opacity-30);
$sidebar-submenu-opacity-15: var(--sidebar-submenu-opacity-15);
$sidebar-submenu-opacity-30: var(--sidebar-submenu-opacity-30);
$sidebar-submenu-opacity-70: var(--sidebar-submenu-opacity-70);
$nav-inverse: var(--nav-inverse);
$nav-inverse-opacity-7: var(--nav-inverse-opacity-7);
$nav-inverse-opacity-10: var(--nav-inverse-opacity-10);

// Borders
$border-default: var(--border-default);
$border-default-opacity-70: var(--border-default-opacity-70);
$border-subtle: var(--border-subtle);
$border-inverse: var(--border-inverse);

// Brand (auth pages)
$brand-default: var(--brand-default);
$brand-emphasis: var(--brand-emphasis);
$fg-onBrand: var(--fg-onBrand);

// Customisable semantic variables
$neutral-default: var(--neutral-default);
$neutral-emphasis: var(--neutral-emphasis);
$neutral-emphasis-opacity-15: var(--neutral-emphasis-opacity-15);
$neutral-tint: var(--neutral-tint);
$neutral-subtle: var(--neutral-subtle);
$neutral-muted: var(--neutral-muted);
$neutral-dark: var(--neutral-dark);
$neutral-fg: var(--neutral-fg);
$neutral-fg-emphasis: var(--neutral-fg-emphasis);
$fg-onNeutral: var(--fg-onNeutral);
$fg-onNeutral-dark: var(--fg-onNeutral-dark);
$fg-onNeutral-dark-opacity-80: var(--fg-onNeutral-dark-opacity-80);
$fg-onNeutral-dark-opacity-0: var(--fg-onNeutral-dark-opacity-0);
$fg-onNeutral-dark-opacity-30: var(--fg-onNeutral-dark-opacity-30);
$neutral-border: var(--neutral-border);
$neutral-border-emphasis: var(--neutral-border-emphasis);
$neutral-subtle-opacity-50: var(--neutral-subtle-opacity-50);
$neutral-opacity-15: var(--neutral-opacity-15);
$neutral-opacity-70: var(--neutral-opacity-70);
$neutral-dark-opacity-80: var(--neutral-dark-opacity-80);
$neutral-dark-opacity-75: var(--neutral-dark-opacity-75);
$neutral-dark-opacity-60: var(--neutral-dark-opacity-60);
$neutral-dark-opacity-20: var(--neutral-dark-opacity-20);
$neutral-dark-opacity-8: var(--neutral-dark-opacity-8);
$neutral-dark-opacity-3: var(--neutral-dark-opacity-3);
$neutral-border-opacity-45: var(--neutral-border-opacity-45);
$neutral-border-emphasis-opacity-8: var(--neutral-border-emphasis-opacity-8);

$accent-default: var(--accent-default);
$accent-emphasis: var(--accent-emphasis);
$accent-tint: var(--accent-tint);
$accent-subtle: var(--accent-subtle);
$accent-muted: var(--accent-muted);
$accent-fg: var(--accent-fg);
$accent-fg-emphasis: var(--accent-fg-emphasis);
$fg-onAccent: var(--fg-onAccent);
$accent-border: var(--accent-border);
$accent-border-emphasis: var(--accent-border-emphasis);
$accent-subtle-opacity-50: var(--accent-subtle-opacity-50);
$accent-border-opacity-70: var(--accent-border-opacity-70);
$accent-border-opacity-50: var(--accent-border-opacity-50);
$accent-border-opacity-45: var(--accent-border-opacity-45);
$accent-border-opacity-30: var(--accent-border-opacity-30);
$accent-border-emphasis-opacity-20: var(--accent-border-emphasis-opacity-20);
$accent-muted-opacity-15: var(--accent-muted-opacity-15);
$accent-muted-opacity-10: var(--accent-muted-opacity-10);

$edit-default: var(--edit-default);
$edit-emphasis: var(--edit-emphasis);
$edit-tint: var(--edit-tint);
$edit-subtle: var(--edit-subtle);
$edit-muted: var(--edit-muted);
$edit-dark: var(--edit-dark);
$edit-bold: var(--edit-bold);
$edit-fg: var(--edit-fg);
$edit-fg-emphasis: var(--edit-fg-emphasis);
$edit-toggle-bg: var(--edit-toggle-bg);
$fg-onEdit: var(--fg-onEdit);
$fg-onEdit-opacity-80: var(--fg-onEdit-opacity-80);
$fg-onEdit-opacity-15: var(--fg-onEdit-opacity-15);
$fg-onEdit-dark: var(--fg-onEdit-dark);
$fg-onEdit-dark-opacity-60: var(--fg-onEdit-dark-opacity-60);
$fg-onEdit-dark-opacity-50: var(--fg-onEdit-dark-opacity-50);
$fg-onEdit-dark-opacity-30: var(--fg-onEdit-dark-opacity-30);
$fg-onEdit-dark-opacity-15: var(--fg-onEdit-dark-opacity-15);
$fg-onEdit-toggle-opacity-60: var(--fg-onEdit-toggle-opacity-60);
$edit-border: var(--edit-border);
$edit-border-emphasis: var(--edit-border-emphasis);
$edit-border-opacity-50: var(--edit-border-opacity-50);

$test-default: var(--test-default);
$test-emphasis: var(--test-emphasis);
$test-tint: var(--test-tint);
$test-subtle: var(--test-subtle);
$test-muted: var(--test-muted);
$test-dark: var(--test-dark);
$test-bold: var(--test-bold);
$test-fg: var(--test-fg);
$test-fg-emphasis: var(--test-fg-emphasis);
$test-toggle-bg: var(--test-toggle-bg);
$fg-onTest: var(--fg-onTest);
$fg-onTest-dark: var(--fg-onTest-dark);
$fg-onTest-dark-opacity-60: var(--fg-onTest-dark-opacity-60);
$fg-onTest-dark-opacity-50: var(--fg-onTest-dark-opacity-50);
$fg-onTest-dark-opacity-30: var(--fg-onTest-dark-opacity-30);
$fg-onTest-dark-opacity-15: var(--fg-onTest-dark-opacity-15);
$fg-onTest-toggle-opacity-60: var(--fg-onTest-toggle-opacity-60);
$test-border: var(--test-border);
$test-border-emphasis: var(--test-border-emphasis);
$test-border-opacity-50: var(--test-border-opacity-50);

$test-alt-default: var(--test-alt-default);
$test-alt-emphasis: var(--test-alt-emphasis);
$test-alt-tint: var(--test-alt-tint);
$test-alt-subtle: var(--test-alt-subtle);
$test-alt-muted: var(--test-alt-muted);
$test-alt-dark: var(--test-alt-dark);
$test-alt-bold: var(--test-alt-bold);
$test-alt-fg: var(--test-alt-fg);
$test-alt-fg-emphasis: var(--test-alt-fg-emphasis);
$test-alt-toggle-bg: var(--test-alt-toggle-bg);
$fg-onTest-alt: var(--fg-onTest-alt);
$fg-onTest-alt-dark: var(--fg-onTest-alt-dark);
$fg-onTest-alt-dark-opacity-60: var(--fg-onTest-alt-dark-opacity-60);
$fg-onTest-alt-dark-opacity-50: var(--fg-onTest-alt-dark-opacity-50);
$fg-onTest-alt-dark-opacity-30: var(--fg-onTest-alt-dark-opacity-30);
$fg-onTest-alt-dark-opacity-15: var(--fg-onTest-alt-dark-opacity-15);
$fg-onTest-alt-toggle-opacity-60: var(--fg-onTest-alt-toggle-opacity-60);
$test-alt-border: var(--test-alt-border);
$test-alt-border-emphasis: var(--test-alt-border-emphasis);
$test-alt-border-opacity-50: var(--test-alt-border-opacity-50);

$info-default: var(--info-default);
$info-emphasis: var(--info-emphasis);
$info-tint: var(--info-tint);
$info-subtle: var(--info-subtle);
$info-muted: var(--info-muted);
$info-fg: var(--info-fg);
$info-fg-emphasis: var(--info-fg-emphasis);
$fg-onInfo: var(--fg-onInfo);
$info-border: var(--info-border);
$info-border-emphasis: var(--info-border-emphasis);
$info-border-opacity-50: var(--info-border-opacity-50);

$success-default: var(--success-default);
$success-emphasis: var(--success-emphasis);
$success-tint: var(--success-tint);
$success-subtle: var(--success-subtle);
$success-muted: var(--success-muted);
$success-fg: var(--success-fg);
$success-fg-emphasis: var(--success-fg-emphasis);
$fg-onSuccess: var(--fg-onSuccess);
$success-border: var(--success-border);
$success-border-emphasis: var(--success-border-emphasis);
$success-border-opacity-50: var(--success-border-opacity-50);

$warning-default: var(--warning-default);
$warning-emphasis: var(--warning-emphasis);
$warning-tint: var(--warning-tint);
$warning-subtle: var(--warning-subtle);
$warning-muted: var(--warning-muted);
$warning-fg: var(--warning-fg);
$warning-fg-emphasis: var(--warning-fg-emphasis);
$fg-onWarning: var(--fg-onWarning);
$warning-border: var(--warning-border);
$warning-border-emphasis: var(--warning-border-emphasis);
$warning-border-opacity-50: var(--warning-border-opacity-50);
$warning-muted-opacity-15: var(--warning-muted-opacity-15);

$error-default: var(--error-default);
$error-emphasis: var(--error-emphasis);
$error-tint: var(--error-tint);
$error-subtle: var(--error-subtle);
$error-muted: var(--error-muted);
$error-fg: var(--error-fg);
$error-fg-emphasis: var(--error-fg-emphasis);
$fg-onError: var(--fg-onError);
$error-border: var(--error-border);
$error-border-emphasis: var(--error-border-emphasis);
$error-border-opacity-50: var(--error-border-opacity-50);
$error-default-opacity-15: var(--error-default-opacity-15);
$error-muted-opacity-15: var(--error-muted-opacity-15);

$notice-default: var(--notice-default);
$notice-emphasis: var(--notice-emphasis);
$notice-tint: var(--notice-tint);
$notice-subtle: var(--notice-subtle);
$notice-muted: var(--notice-muted);
$notice-fg: var(--notice-fg);
$notice-fg-emphasis: var(--notice-fg-emphasis);
$fg-onNotice: var(--fg-onNotice);
$notice-border: var(--notice-border);
$notice-border-emphasis: var(--notice-border-emphasis);
$notice-border-opacity-50: var(--notice-border-opacity-50);

// Non-customisable(but theme dependant) variables

$ai-default: var(--ai-default);
$ai-emphasis: var(--ai-emphasis);
$ai-tint: var(--ai-tint);
$ai-subtle: var(--ai-subtle);
$ai-muted: var(--ai-muted);
$ai-fg: var(--ai-fg);
$ai-fg-emphasis: var(--ai-fg-emphasis);
$fg-onAi: var(--fg-onAi);
$ai-border: var(--ai-border);
$ai-border-emphasis: var(--ai-border-emphasis);
$ai-border-opacity-50: var(--ai-border-opacity-50);
$ai-border-opacity-70: var(--ai-border-opacity-70);

$ai-alt-default: var(--ai-alt-default);
$ai-alt-emphasis: var(--ai-alt-emphasis);
$ai-alt-tint: var(--ai-alt-tint);
$ai-alt-subtle: var(--ai-alt-subtle);
$ai-alt-muted: var(--ai-alt-muted);
$ai-alt-fg: var(--ai-alt-fg);
$ai-alt-fg-emphasis: var(--ai-alt-fg-emphasis);
$fg-onAi-alt: var(--fg-onAi-alt);
$ai-alt-border: var(--ai-alt-border);
$ai-alt-border-emphasis: var(--ai-alt-border-emphasis);
$ai-alt-border-opacity-50: var(--ai-alt-border-opacity-50);
$ai-alt-border-opacity-70: var(--ai-alt-border-opacity-70);

$copilot-primary: var(--copilot-primary);
$copilot-secondary: var(--copilot-secondary);
$copilot-alt: var(--copilot-alt);

$env-dev-default: var(--env-dev-default);
$env-dev-emphasis: var(--env-dev-emphasis);
$env-dev-tint: var(--env-dev-tint);
$env-dev-subtle: var(--env-dev-subtle);
$env-dev-muted: var(--env-dev-muted);
$env-dev-fg: var(--env-dev-fg);
$env-dev-fg-emphasis: var(--env-dev-fg-emphasis);
$fg-onEnv-dev: var(--fg-onEnv-dev);
$env-dev-border: var(--env-dev-border);
$env-dev-border-emphasis: var(--env-dev-border-emphasis);
$env-dev-opacity-80: var(--env-dev-opacity-80);
$env-dev-opacity-30: var(--env-dev-opacity-30);

$env-test-default: var(--env-test-default);
$env-test-emphasis: var(--env-test-emphasis);
$env-test-tint: var(--env-test-tint);
$env-test-subtle: var(--env-test-subtle);
$env-test-muted: var(--env-test-muted);
$env-test-fg: var(--env-test-fg);
$env-test-fg-emphasis: var(--env-test-fg-emphasis);
$fg-onEnv-test: var(--fg-onEnv-test);
$env-test-border: var(--env-test-border);
$env-test-border-emphasis: var(--env-test-border-emphasis);
$env-test-opacity-80: var(--env-test-opacity-80);
$env-test-opacity-30: var(--env-test-opacity-30);

$env-prod-default: var(--env-prod-default);
$env-prod-emphasis: var(--env-prod-emphasis);
$env-prod-tint: var(--env-prod-tint);
$env-prod-subtle: var(--env-prod-subtle);
$env-prod-muted: var(--env-prod-muted);
$env-prod-fg: var(--env-prod-fg);
$env-prod-fg-emphasis: var(--env-prod-fg-emphasis);
$fg-onEnv-prod: var(--fg-onEnv-prod);
$env-prod-border: var(--env-prod-border);
$env-prod-border-emphasis: var(--env-prod-border-emphasis);
$env-prod-opacity-80: var(--env-prod-opacity-80);
$env-prod-opacity-30: var(--env-prod-opacity-30);

$utility-bg-teal: var(--utility-bg-teal);
$utility-bg-gold: var(--utility-bg-gold);
$utility-bg-blue: var(--utility-bg-blue);
$utility-bg-orange: var(--utility-bg-orange);
$utility-bg-red: var(--utility-bg-red);
$utility-bg-slate: var(--utility-bg-slate);
$utility-bg-neutral: var(--utility-bg-neutral);
$utility-fg-teal: var(--utility-fg-teal);
$utility-fg-gold: var(--utility-fg-gold);
$utility-fg-blue: var(--utility-fg-blue);
$utility-fg-orange: var(--utility-fg-orange);
$utility-fg-red: var(--utility-fg-red);
$utility-fg-slate: var(--utility-fg-slate);
$utility-fg-neutral: var(--utility-fg-neutral);
$utility-border: var(--utility-border);
$utility-border-opacity-20: var(--utility-border-opacity-20);

$rust-default: var(--rust-default);
$rust-emphasis: var(--rust-emphasis);
$rust-tint: var(--rust-tint);
$rust-subtle: var(--rust-subtle);
$rust-muted: var(--rust-muted);
$rust-fg: var(--rust-fg);
$rust-fg-emphasis: var(--rust-fg-emphasis);
$fg-onRust: var(--fg-onRust);
$rust-border: var(--rust-border);
$rust-border-emphasis: var(--rust-border-emphasis);
$rust-opacity-80: var(--rust-opacity-80);
$rust-opacity-30: var(--rust-opacity-30);

$olive-default: var(--olive-default);
$olive-emphasis: var(--olive-emphasis);
$olive-tint: var(--olive-tint);
$olive-subtle: var(--olive-subtle);
$olive-muted: var(--olive-muted);
$olive-fg: var(--olive-fg);
$olive-fg-emphasis: var(--olive-fg-emphasis);
$fg-onOlive: var(--fg-onOlive);
$olive-border: var(--olive-border);
$olive-border-emphasis: var(--olive-border-emphasis);
$olive-opacity-80: var(--olive-opacity-80);
$olive-opacity-30: var(--olive-opacity-30);

$plum-default: var(--plum-default);
$plum-emphasis: var(--plum-emphasis);
$plum-tint: var(--plum-tint);
$plum-subtle: var(--plum-subtle);
$plum-muted: var(--plum-muted);
$plum-fg: var(--plum-fg);
$plum-fg-emphasis: var(--plum-fg-emphasis);
$fg-onPlum: var(--fg-onPlum);
$plum-border: var(--plum-border);
$plum-border-emphasis: var(--plum-border-emphasis);
$plum-opacity-80: var(--plum-opacity-80);

$violet-default: var(--violet-default);
$violet-emphasis: var(--violet-emphasis);
$violet-tint: var(--violet-tint);
$violet-subtle: var(--violet-subtle);
$violet-muted: var(--violet-muted);
$violet-fg: var(--violet-fg);
$violet-fg-emphasis: var(--violet-fg-emphasis);
$fg-onViolet: var(--fg-onViolet);
$violet-border: var(--violet-border);
$violet-border-emphasis: var(--violet-border-emphasis);
$violet-opacity-80: var(--violet-opacity-80);

$purple-default: var(--purple-default);
$purple-emphasis: var(--purple-emphasis);
$purple-tint: var(--purple-tint);
$purple-subtle: var(--purple-subtle);
$purple-muted: var(--purple-muted);
$purple-fg: var(--purple-fg);
$purple-fg-emphasis: var(--purple-fg-emphasis);
$fg-onPurple: var(--fg-onPurple);
$purple-border: var(--purple-border);
$purple-border-emphasis: var(--purple-border-emphasis);
$purple-opacity-80: var(--purple-opacity-80);

$gold-default: var(--gold-default);
$gold-emphasis: var(--gold-emphasis);
$gold-tint: var(--gold-tint);
$gold-subtle: var(--gold-subtle);
$gold-muted: var(--gold-muted);
$gold-fg: var(--gold-fg);
$gold-fg-emphasis: var(--gold-fg-emphasis);
$fg-onGold: var(--fg-onGold);
$gold-border: var(--gold-border);
$gold-border-emphasis: var(--gold-border-emphasis);
$gold-opacity-80: var(--gold-opacity-80);

$brown-default: var(--brown-default);
$brown-emphasis: var(--brown-emphasis);
$brown-tint: var(--brown-tint);
$brown-subtle: var(--brown-subtle);
$brown-muted: var(--brown-muted);
$brown-fg: var(--brown-fg);
$brown-fg-emphasis: var(--brown-fg-emphasis);
$fg-onBrown: var(--fg-onBrown);
$brown-border: var(--brown-border);
$brown-border-emphasis: var(--brown-border-emphasis);
$brown-opacity-80: var(--brown-opacity-80);

$forest-default: var(--forest-default);
$forest-emphasis: var(--forest-emphasis);
$forest-tint: var(--forest-tint);
$forest-subtle: var(--forest-subtle);
$forest-muted: var(--forest-muted);
$forest-fg: var(--forest-fg);
$forest-fg-emphasis: var(--forest-fg-emphasis);
$fg-onForest: var(--fg-onForest);
$forest-border: var(--forest-border);
$forest-border-emphasis: var(--forest-border-emphasis);
$forest-opacity-80: var(--forest-opacity-80);

$teal-default: var(--teal-default);
$teal-emphasis: var(--teal-emphasis);
$teal-tint: var(--teal-tint);
$teal-subtle: var(--teal-subtle);
$teal-muted: var(--teal-muted);
$teal-fg: var(--teal-fg);
$teal-fg-emphasis: var(--teal-fg-emphasis);
$fg-onTeal: var(--fg-onTeal);
$teal-border: var(--teal-border);
$teal-border-emphasis: var(--teal-border-emphasis);
$teal-opacity-80: var(--teal-opacity-80);
$teal-opacity-40: var(--teal-opacity-40);

$blue-default: var(--blue-default);
$blue-emphasis: var(--blue-emphasis);
$blue-tint: var(--blue-tint);
$blue-subtle: var(--blue-subtle);
$blue-muted: var(--blue-muted);
$blue-fg: var(--blue-fg);
$blue-fg-emphasis: var(--blue-fg-emphasis);
$fg-onBlue: var(--fg-onBlue);
$blue-border: var(--blue-border);
$blue-border-emphasis: var(--blue-border-emphasis);
$blue-opacity-80: var(--blue-opacity-80);
$blue-subtle-opacity-20: var(--blue-subtle-opacity-20);
$blue-subtle-opacity-50: var(--blue-subtle-opacity-50);
$blue-muted-opacity-15: var(--blue-muted-opacity-15);
$blue-border-emphasis-opacity-50: var(--blue-border-emphasis-opacity-50);

$indigo-default: var(--indigo-default);
$indigo-emphasis: var(--indigo-emphasis);
$indigo-tint: var(--indigo-tint);
$indigo-subtle: var(--indigo-subtle);
$indigo-muted: var(--indigo-muted);
$indigo-fg: var(--indigo-fg);
$indigo-fg-emphasis: var(--indigo-fg-emphasis);
$fg-onIndigo: var(--fg-onIndigo);
$indigo-border: var(--indigo-border);
$indigo-border-emphasis: var(--indigo-border-emphasis);
$indigo-opacity-80: var(--indigo-opacity-80);
$indigo-opacity-30: var(--indigo-opacity-30);
$indigo-opacity-15: var(--indigo-opacity-15);

$green-default: var(--green-default);
$green-emphasis: var(--green-emphasis);
$green-tint: var(--green-tint);
$green-subtle: var(--green-subtle);
$green-muted: var(--green-muted);
$green-fg: var(--green-fg);
$green-fg-emphasis: var(--green-fg-emphasis);
$fg-onGreen: var(--fg-onGreen);
$green-border: var(--green-border);
$green-border-emphasis: var(--green-border-emphasis);
$green-opacity-80: var(--green-opacity-80);
$green-muted-opacity-15: var(--green-muted-opacity-15);
$green-subtle-opacity-50: var(--green-subtle-opacity-50);
$green-border-emphasis-opacity-50: var(--green-border-emphasis-opacity-50);

$orange-default: var(--orange-default);
$orange-emphasis: var(--orange-emphasis);
$orange-tint: var(--orange-tint);
$orange-subtle: var(--orange-subtle);
$orange-muted: var(--orange-muted);
$orange-fg: var(--orange-fg);
$orange-fg-emphasis: var(--orange-fg-emphasis);
$fg-onOrange: var(--fg-onOrange);
$orange-border: var(--orange-border);
$orange-border-emphasis: var(--orange-border-emphasis);
$orange-opacity-80: var(--orange-opacity-80);
$orange-muted-opacity-15: var(--orange-muted-opacity-15);
$orange-subtle-opacity-50: var(--orange-subtle-opacity-50);

$red-default: var(--red-default);
$red-emphasis: var(--red-emphasis);
$red-tint: var(--red-tint);
$red-subtle: var(--red-subtle);
$red-muted: var(--red-muted);
$red-fg: var(--red-fg);
$red-fg-emphasis: var(--red-fg-emphasis);
$fg-onRed: var(--fg-onRed);
$red-border: var(--red-border);
$red-border-emphasis: var(--red-border-emphasis);
$red-opacity-80: var(--red-opacity-80);
$red-subtle-opacity-20: var(--red-subtle-opacity-20);
$red-subtle-opacity-50: var(--red-subtle-opacity-50);
$red-muted-opacity-15: var(--red-muted-opacity-15);
$red-border-emphasis-opacity-50: var(--red-border-emphasis-opacity-50);

$slate-default: var(--slate-default);
$slate-emphasis: var(--slate-emphasis);
$slate-tint: var(--slate-tint);
$slate-subtle: var(--slate-subtle);
$slate-muted: var(--slate-muted);
$slate-fg: var(--slate-fg);
$slate-fg-emphasis: var(--slate-fg-emphasis);
$fg-onSlate: var(--fg-onSlate);
$slate-border: var(--slate-border);
$slate-border-emphasis: var(--slate-border-emphasis);
$slate-opacity-80: var(--slate-opacity-80);

$chart-teal-muted: var(--chart-teal-muted);
$chart-teal-subtle: var(--chart-teal-subtle);
$chart-teal-tint: var(--chart-teal-tint);
$chart-teal-fg: var(--chart-teal-fg);
$chart-teal-border: var(--chart-teal-border);
$chart-blue-muted: var(--chart-blue-muted);
$chart-blue-subtle: var(--chart-blue-subtle);
$chart-blue-tint: var(--chart-blue-tint);
$chart-blue-fg: var(--chart-blue-fg);
$chart-blue-border: var(--chart-blue-border);
$chart-violet-muted: var(--chart-violet-muted);
$chart-violet-subtle: var(--chart-violet-subtle);
$chart-violet-tint: var(--chart-violet-tint);
$chart-violet-fg: var(--chart-violet-fg);
$chart-violet-border: var(--chart-violet-border);
$chart-red-muted: var(--chart-red-muted);
$chart-red-subtle: var(--chart-red-subtle);
$chart-red-tint: var(--chart-red-tint);
$chart-red-fg: var(--chart-red-fg);
$chart-red-border: var(--chart-red-border);
$chart-green-muted: var(--chart-green-muted);
$chart-green-subtle: var(--chart-green-subtle);
$chart-green-tint: var(--chart-green-tint);
$chart-green-fg: var(--chart-green-fg);
$chart-green-border: var(--chart-green-border);
$chart-brown-muted: var(--chart-brown-muted);
$chart-brown-subtle: var(--chart-brown-subtle);
$chart-brown-tint: var(--chart-brown-tint);
$chart-brown-fg: var(--chart-brown-fg);
$chart-brown-border: var(--chart-brown-border);
$chart-orange-muted: var(--chart-orange-muted);
$chart-orange-subtle: var(--chart-orange-subtle);
$chart-orange-tint: var(--chart-orange-tint);
$chart-orange-fg: var(--chart-orange-fg);
$chart-orange-border: var(--chart-orange-border);
$chart-gold-muted: var(--chart-gold-muted);
$chart-gold-subtle: var(--chart-gold-subtle);
$chart-gold-tint: var(--chart-gold-tint);
$chart-gold-fg: var(--chart-gold-fg);
$chart-gold-border: var(--chart-gold-border);
$chart-indigo-muted: var(--chart-indigo-muted);
$chart-indigo-subtle: var(--chart-indigo-subtle);
$chart-indigo-tint: var(--chart-indigo-tint);
$chart-indigo-fg: var(--chart-indigo-fg);
$chart-indigo-border: var(--chart-indigo-border);
$chart-plum-muted: var(--chart-plum-muted);
$chart-plum-subtle: var(--chart-plum-subtle);
$chart-plum-tint: var(--chart-plum-tint);
$chart-plum-fg: var(--chart-plum-fg);
$chart-plum-border: var(--chart-plum-border);
$chart-slate-muted: var(--chart-slate-muted);
$chart-slate-subtle: var(--chart-slate-subtle);
$chart-slate-tint: var(--chart-slate-tint);
$chart-slate-fg: var(--chart-slate-fg);
$chart-slate-border: var(--chart-slate-border);
$chart-rose-muted: var(--chart-rose-muted);
$chart-rose-subtle: var(--chart-rose-subtle);
$chart-rose-tint: var(--chart-rose-tint);
$chart-rose-fg: var(--chart-rose-fg);
$chart-rose-border: var(--chart-rose-border);
$chart-yellow-muted: var(--chart-yellow-muted);
$chart-yellow-subtle: var(--chart-yellow-subtle);
$chart-yellow-tint: var(--chart-yellow-tint);
$chart-yellow-fg: var(--chart-yellow-fg);
$chart-yellow-border: var(--chart-yellow-border);
$chart-cobalt-muted: var(--chart-cobalt-muted);
$chart-cobalt-subtle: var(--chart-cobalt-subtle);
$chart-cobalt-tint: var(--chart-cobalt-tint);
$chart-cobalt-fg: var(--chart-cobalt-fg);
$chart-cobalt-border: var(--chart-cobalt-border);

$mktg-neutral-default: var(--mktg-neutral-default);
$mktg-neutral-emphasis: var(--mktg-neutral-emphasis);
$fg-onMktg-neutral: var(--fg-onMktg-neutral);

$usage-dashboard-tint: var(--usage-dashboard-tint);
$usage-dashboard-alt-tint: var(--usage-dashboard-alt-tint);
$usage-dashboard-alt-border: var(--usage-dashboard-alt-border);
