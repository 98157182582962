@use "variables" as *;
@use "mixins" as *;

.w-page-global {
  background-color: $backdrop-default;

  &_pushed {
    @include device(compact) {
      overflow: hidden;
    }
  }
}
