@use "variables" as *;

.tree-select-nodes {
  border: none;
  border-top: 1px solid $border-subtle;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;

  &_root {
    border-top: none;
  }

  &__branch + &__branch {
    border-top: 1px solid $border-subtle;
  }

  &__empty-msg {
    color: $text-secondary;
    display: inline-block;
    padding-left: 10px;
  }

  &__ruler {
    border-left: 1px solid $border-subtle;
    bottom: 0;
    display: none;
    position: absolute;
    top: 0;
    width: 1px;
  }

  &__node {
    align-items: center;
    display: flex;
    padding: 6px;
    position: relative;

    &_selected {
      font-weight: 700;
    }
  }

  &__node-content {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex: 1 1 auto;
    min-height: 36px;
    padding: 4px;

    &:hover,
    .tree-select-nodes__node_active & {
      background: $surface-inverse-opacity-5;
    }

    &_disabled {
      cursor: default;
      opacity: 0.5;
    }
  }

  &__node-checkbox[type="checkbox"] {
    margin: 0 10px 0 0;
  }

  &__node-hint {
    color: $text-secondary;
    font-size: 12px;
    font-weight: 400;
  }

  &__node-expander {
    align-items: center;
    align-self: stretch;
    color: $neutral-muted;
    display: flex;
    flex-shrink: 0;
    justify-content: flex-end;
    padding-right: 8px;
    position: relative;
    transition: color 0.1s ease;
    width: 30px;

    .tree-select-nodes__node_selected-path & {
      color: $text-primary;
    }

    &:hover {
      color: $teal-fg;
    }

    &_clickable {
      cursor: pointer;
    }
  }

  &__node-expander-icon {
    display: block;
    transition: transform 0.1s ease;

    .tree-select-nodes__node_expanded & {
      transform: rotate(90deg);
    }
  }

  &__node-icon {
    color: $icon-default;
    height: 22px;
    margin-right: 10px;
    width: 22px;
  }

  &__node-spinner {
    margin-right: -4px;
  }
}
